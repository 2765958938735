import { getDocFlowUrl, getToken, target } from '@/utils';

export const docflow_service = 'docflow';

export const docflow_api = `/document/docflow_openapi_guide`;

export const toDocFlow = () => {
  const contractURL = getDocFlowUrl();
  const url = `/activate/docflow?service=${docflow_service}&redirect=${encodeURIComponent(
    contractURL,
  )}`;
  if (getToken()) {
    window.open(url);
  } else {
    window.open(`/user/login?redirect=${encodeURIComponent(url)}`);
  }
};

export const BannerData = {
  title: ['DocFlow', '票据自动化'],
  desc: 'AI自动化处理，支持不同版式单据智能分类抽取，可选功能插件配置流程，同时提供Rule-Validation和人工精准识别BPO，满足多样业务场景需求。',
  backgroundImage: '/images/product/docflow/pic_banner_docflow@2x.png',
  btnText: '在线试用',
};

export const AdvanceData = {
  advanceList: [
    {
      icon: '/images/product/docflow/icon_finance_adv_01@2x.png',
      keyword: '全面',
      desc: '开箱即用，单据类型、抽取字段均可自定义',
    },
    {
      icon: '/images/product/docflow/icon_finance_adv_02@2x.png',
      keyword: '准确',
      desc: '少量样本精准分类，识别精准并提供框选和位置关联查看',
    },
    {
      icon: '/images/product/docflow/icon_finance_adv_03@2x.png',
      keyword: '扩展',
      desc: '多类插件可以进行流程编排，满足不同业务场景',
    },
  ],
};

export const PlatformSceneData = {
  title: '产品特色功能',
  sceneList: [
    {
      name: '文件预处理',
      desc: '一键优化图像质量；支持一页图片多张单据自动切分、一份文档包含多套单据自动拆分',
      image: '/images/product/docflow/feature01.png',
    },
    {
      name: '分类抽取',
      desc: '分类可自定义，少量样本即可实现精准分类，抽取字段开放定义，页面框选定位查看结果',
      image: '/images/product/docflow/feature02.png',
    },
    {
      name: '扩展插件',
      desc: '插件类型包括导入、数据处理、导出，随用随开，可根据实际业务需求自行选择应用',
      image: '/images/product/docflow/feature03.png',
    },
  ],
};

export const SceneData = {
  title: '应用场景',
  sceneList: [
    {
      image: '/images/product/knowledge_base/pic_customer_bg_01@2x.png',
      name: '金融保险',
      desc: '',
      cardType: 'desc',
      cards: [
        {
          name: '金融反洗钱',
          desc: [
            {
              title: '信贷审批和风险评估：',
              text: '通过快速评估申请人资料、评估风险因素、利用数据分析和生成审批建议，提高决策效率，同时确保合规性。',
            },
            {
              title: '合规和监管报告：',
              text: '跨部门验证数据，验证条目，确保合规性，并定期向监管机构提交准确的财务、风险和合规性报告。',
            },
          ],
          type: '细分场景',
        },
        {
          name: '保险理赔',
          desc: [
            {
              text: '加快应用程序审查、增强风险评估并确保无缝合规，从而在政策评估中实现更快、更一致的决策。',
            },
            {
              text: '加快索赔评估、准确验证数据、减少手动错误、增强决策、加快支付、确保合规性并提高客户满意度。',
            },
          ],
          type: '细分场景',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_02@2x.png',
      name: '财务共享中心',
      desc: '',
      cardType: 'desc',
      cards: [
        {
          name: '应付场景',
          desc: [
            {
              text: '高效地处理发票、匹配采购订单、验证付款、确保及时收款、减少错误、提供实时报告、保持合规性、加快审批并改进现金流管理。',
            },
            {
              text: '匹配交易、识别差异、确保准确性、生成报告、通知利益相关者、加快任务完成、整合数据、强制执行合规性、减少错误并提高审计准备程度。',
            },
          ],
          type: '细分场景',
        },
        {
          name: '费用报销',
          desc: [
            {
              text: '多渠道采集，批量识别，借助AI审核能力自动预警风险项，提升企业财务合规性和审核效率。',
            },
            {
              text: '兼容多种票据，可自定义并添加分类，通过插件定义审核规则，为企业发票的真实性和合规性保驾护航。',
            },
          ],
          type: '细分场景',
        },
      ],
    },
    {
      image: '/images/product/knowledge_base/pic_customer_bg_03@2x.png',
      name: '进出口物流',
      desc: '开箱即用专有AI可以读取、解释和提取商业发票表格、提单和客户文档中的数据。自动化运输物流文档，获取运输、货运和货物信息，减少管理文件和数据难度，提高后台办公效率。\n高精度的数据采集解决方案，简化报告、运营和合规性。',
      cardType: 'img',
      cards: [
        {
          img: '/images/product/docflow/bill01.png',
          name: '商业发票和形式发票',
        },
        {
          img: '/images/product/docflow/bill02.png',
          name: '装箱单和提单',
        },
        {
          img: '/images/product/docflow/bill03.png',
          name: '危险货物表格和海关文件',
        },
      ],
    },
  ],
};

export const deployList = [
  {
    title: '公有云API',
    logo: '/images/scene/pic_deploy_open-cloud_01@2x.png',
    desc: '16年技术沉淀，安全、可靠、迅速的公有云服务，可直接调用API使用TextIn智能文档识别产品',
    anchors: [{ text: '立即体验' }],
    cardLink: docflow_api,
  },
  {
    title: '私有化部署',
    logo: '/images/scene/pic_deploy_open-cloud_02@2x.png',
    desc: '可部署至本地服务器，进一步保障数据安全。支持 GPU、CPU 环境及国产化操作系统部署',
    anchors: [{ href: `/contact?type=27`, text: '提交需求' }],
    cardLink: `/contact?type=27`,
  },
];
