import { target } from '@/utils/common';
import { toContract } from '../Product/dataSource';
import { toIntFinQ } from '../Product/dataSource/knowledge_base';
import { toDocFlow } from '../Product/dataSource/docflow';

export const metaConfig = {
  title: 'TextIn - 一站式OCR服务平台免费使用',
  description:
    'TextIn 合合旗下智能文字识别品牌，专注智能文字识别16年，为扫描全能王、名片全能王提供OCR能力，是集智能文字识别技术、产品、服务于一身的一站式OCR服务平台，为全球数千家企业及上亿用户提供服务。',
};
export const BannerDataSource = {
  content: {
    subTitle: '合合TextIn',
    title: '智能文字识别产品',
    children: [
      // '合合信息旗下一站式OCR云服务平台',
      // '为“扫描全能王”、“名片全能王”提供文字识别引擎',
      // 'OCR技术、产品、服务提供商',
    ],
  },
};

export const ProductDataSource = {
  titleWrapper: {
    title: '智能文档处理',
    href: '/market/list',
  },
  cards: [
    {
      title: '通用识别',
      des: '基础文字与表格识别',
      content: [['通用文字', '通用表格', '印章检测'], ['二维码']],
      moreLink: {
        href: '/product/textin_text',
        name: '了解更多',
      },
      image: '/images/home/pic-currency@2x.png',
    },
    {
      title: '通用文档解析',
      des: '对大模型友好的PDF解析',
      content: [['100页PDF仅需2s', '支持任意PDF'], ['能精准还原表格']],
      // dot: true,
      moreLink: {
        href: '/market/detail/pdf_to_markdown',
        name: '了解更多',
      },
      image:
        'https://web-api.textin.com/open/image/download?filename=ec8f6f0a56f84e6396f3c1475d6596f9',
    },
    {
      title: '智能文档抽取',
      des: '智能理解文档内容，提取关键要素',
      content: [
        ['保单', '海外发票', '卡证'],
        ['行程单', '订货单', '医疗票据'],
        ['合同', '企业内部文档'],
      ],
      // dot: true,
      moreLink: {
        href: '/product/textin_extract',
        name: '详情',
      },
      image:
        'https://web-api.textin.com/open/image/download?filename=763bd3068d2349d78fb3b3558b84c704',
    },
    {
      title: '图像智能处理',
      des: '图像切边增强、PS检测、去屏幕纹',
      content: [
        ['切边增强', 'PS检测', '去屏幕纹'],
        ['自动擦除手写', '切边矫正'],
      ],
      dot: true,
      moreLink: {
        href: '/product/textin_image',
        name: '了解更多',
      },
      image: '/images/home/pic-image@2x.png',
    },
  ],
};

export const SceneDataSource = {
  titleWrapper: {
    title: '深入场景的智能识别应用',
  },
  carousel: [
    {
      title: 'DocFlow 票据自动化',
      children: {
        icon: '/images/home/scene/logo_ai.png',
        title: 'DocFlow 票据自动化',
        desc: 'AI自动化处理，支持不同版式单据智能分类抽取，可选功能插件配置流程，同时提供Rule-Validation和人工精准识别BPO，满足多样业务场景需求。',
        useLink: toDocFlow,
        useText: '在线试用',
        moreLink: '/product/textin_docflow',
        sourceHref: undefined,
        bgSrc: [
          '/images/product/docflow/feature01.png',
          '/images/product/docflow/feature02.png',
          '/images/product/docflow/feature03.png',
        ],
      },
    },
    {
      title: '合同比对',
      children: {
        icon: '/images/home/scene/logo_ht.png',
        title: 'TextIn 合同比对',
        desc: '依托于合合信息强大的文档解析、元素比对技术，提供多格式文档一键比对、差异精准定位、差异结果一键导出等功能。能精准找出不同文档文本段落、表格、印章之间的差异，分钟级别完成百页文档比对。显著降低企业在合同管理全流程中的人工审核成本，减少因合同差异带来的潜在风险。',
        useText: '在线试用',
        useLink: () => toContract('compare'),
        moreLink: '/product/textin_contract',
        sourceHref: '//static.textin.com/videos/web/contract.mp4',
        bgSrc: '/images/home/scene/ai.jpg',
      },
    },
    {
      title: '合同抽取',
      children: {
        icon: '/images/home/scene/logo_ht.png',
        title: 'TextIn 合同抽取',
        desc: 'TextIn合同抽取为您提供合同文档智能化信息抽取服务。该服务基于合合信息强大的文字识别能力，结合了文档解析、文档检索和文本生成三项核心技术。提供多类型合同文档的关键要素提取和比对的能力。',
        useText: '在线试用',
        useLink: () => toContract('extraction'),
        moreLink: '/product/textin_contract_extraction',
        sourceHref: '//static.textin.com/videos/web/contract.mp4',
        bgSrc: '/images/home/scene/ai.jpg',
      },
    },
    {
      title: '知识管理及写作助手',
      children: {
        icon: '/images/home/scene/intfinq.png',
        title: 'IntFinQ 知识管理及写作助手',
        desc: '支持多种文件格式，轻松打造个人知识库，通过知识检索、智能辅助创作，有效提升知识写作质量及效率，成为您的高效知识写作助手',
        useLink: toIntFinQ,
        useText: '在线试用',
        moreLink: '/product/textin_intfinq',
        sourceHref: undefined,
        bgSrc: [
          '/images/product/knowledge_base/feature01@2x.png',
          '/images/product/knowledge_base/feature02@2x.png',
          '/images/product/knowledge_base/feature03@2x.png',
        ],
      },
    },
  ],
};

export const PlanDataSource = {
  titleWrapper: {
    title: '支持多种技术实现方案',
    content: '多种部署方式，满足不同业务场景技术需求',
  },
  blocks: [
    {
      title: '公有云API',
      id: 0,
      content: '16年技术沉淀，安全、可靠、迅速的公有云服务，可直接调用API使用TextIn产品',
      imgHref: '/images/home/method_pic_open_cloud@2x.jpg',
      useLink: {
        href: '/experience/list',
        name: '立即体验',
        target: target._blank,
      },
    },
    {
      title: '私有化部署',
      id: 1,
      content:
        '可部署至本地服务器，在私有化环境中使用智能文字识别产品，支持CPU/GPU环境及国产化操作系统部署',
      imgHref: '/images/home/method_pic_private@2x.jpg',
      useLink: {
        href: '/contact?type=11',
        name: '提交需求',
      },
    },
    {
      title: '端侧SDK',
      id: 2,
      content: '支持端侧设备（Android、iOS），无需网络即可使用智能文字识别产品',
      imgHref: '/images/home/method_pic_sdk@2x.jpg',
      useLink: {
        href: '/contact?type=10',
        name: '申请试用',
      },
    },
    {
      title: 'AIoT',
      id: 3,
      content:
        '支持硬件设备集成，扫描仪、扫描笔等多种硬件设备，实现在硬件端侧的实时计算，返回智能文字识别结果',
      imgHref: '/images/home/method_pic_alot@2x.jpg',
      useLink: {
        href: '/contact?type=18',
        name: '提交需求',
      },
    },
  ],
};

export const CustomerDataSource = {
  titleWrapper: {
    title: '我们的客户',
    content: '深耕智能文字识别领域16年，为各行各业提供高标准的服务',
  },
  logos: [
    {
      active: '/images/home/customer/logo_communications@2x.png',
    },
    {
      active: '/images/home/customer/logo_express@2x.png',
    },
    {
      active: '/images/home/customer/logo_hitachi@2x.png',
    },
    {
      active: '/images/home/customer/logo_cifi-group@2x.png',
    },
    {
      active: '/images/home/customer/logo_best-express@2x.png',
    },
    {
      active: '/images/home/customer/logo_huadong-medicine@2x.png',
    },
    {
      active: '/images/home/customer/logo_pingan@2x.png',
    },
    {
      active: '/images/home/customer/logo_yangtze@2x.png',
    },
    {
      active: '/images/home/customer/logo_cosco@2x.png',
    },
    {
      active: '/images/home/customer/logo_wanda-group@2x.png',
    },
    {
      active: '/images/home/customer/logo_haitong@2x.png',
    },
    {
      active: '/images/home/customer/logo_sqdz@2x.png',
    },
    {
      active: '/images/home/customer/logo_china-eastern@2x.png',
    },
    {
      active: '/images/home/customer/logo_picc@2x.png',
    },
    {
      active: '/images/home/customer/logo_orient-securities@2x.png',
    },
    {
      active: '/images/home/customer/logo_boston@2x.png',
    },
    {
      active: '/images/home/customer/logo_sqty@2x.png',
    },
    {
      active: '/images/home/customer/logo_agricultural-bank@2x.png',
    },
  ],
};
